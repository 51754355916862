<template>
<!--  <router-view :key="$route.fullPath" />-->
  <router-view />
</template>

<script setup>
defineOptions({
  name: "App",
});

import { onMounted } from "vue";
import { useAccountStore } from "@/stores/account";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();
const accountStore = useAccountStore();

if (accountStore.accessToken) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accountStore.accessToken}`;
} else {
  axios.defaults.headers.common["Authorization"] = "";
}

async function getAccess() {
  await axios
    .post("api/token/refresh/", {
      refresh: accountStore.refreshToken,
    })
    .then((response) => {
      // console.log(accountStore.accessToken);
      accountStore.accessToken = response.data.access;
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.status === 401 || error.response.status === 500) {
          // return true;
          axios.defaults.headers.common["Authorization"] = "";
          accountStore.accessToken = null;
          accountStore.refreshToken = null;
          router.push({ name: "Auth" });
        }
      }
      // console.log(error);
    });
}

onMounted(() => {
  // console.log("error");
  getAccess();
  setInterval(() => {
    // console.log("error");
    getAccess();
  }, 1000 * 60 * 60 * 12); //1000 * 60 * 60 * 12 (12 часов)
});
</script>

<style scoped>
</style>
