import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import axios from 'axios';
import { ref } from 'vue';

// axios.defaults.withCredentials = true;

export const useAccountStore = defineStore('account', () => {
    const jwt = useLocalStorage('jwt', null);

    const accessToken = useLocalStorage('accessToken', null);
    const refreshToken = useLocalStorage('refreshToken', null);
    // async function initializeStore() {
    //     if (accessToken) {

    //     }
    // }

    // const authorization = ref(null);
    const users = ref(null);
    const tracker = ref(null);

    // async function isAuthorization(username, password) {
    //     const res = await axios.post('https://127.0.0.1/api/token/', {
    //         username: username,
    //         password: password,
    //     })
    //         .then((response) => {
    //             jwt = response.data.access;
    //             router.push({ name: 'Dashboard' });
    //         })
    //         .catch((error) => {
    //             console.log({
    //                 title: 'Не удалось войти',
    //                 message: error.response.data.detail
    //             });
    //         })
    // }

    async function isUsers() {
        const res = await axios.get('api/v1/users/user/?format=json', {
            // headers: {
            //     'Authorization': `Bearer ${accessToken.value}`
            // }
        });
        users.value = res.data;
        return users.value;
    }

    async function isTracker() {
        const res = await axios.get('api/v1/tracker_data/trackerdata/?format=datatables', {
            // headers: {
            //     'Authorization': `Bearer ${accessToken.value}`
            // }
        });
        tracker.value = res.data;
        return tracker.value;
    }

    return { jwt, accessToken, refreshToken, isUsers, users, isTracker, tracker }
}, { persist: true })